<template>
  <v-row justify="center">
    
    <v-col v-if="isPaketEmpty" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada program</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          klik tombol di bawah ini untuk menambahkan program baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0">
            <v-icon>ri-add-line</v-icon> Program baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
			<v-col id="search" lg="6" class="d-flex align-center">          				
				<v-text-field
					outlined
					append-icon="$search"
					class="pt-0 mt-0 mr-4"
					placeholder="Search"
					v-model="name"
					solo
          dense
					flat
          @keydown.enter="fetchNotification">
          <template v-slot:append v-if="name">
            <v-icon @click="name = '';fetchNotification()">ri-close-line</v-icon>
          </template> 
        </v-text-field>          
        <v-btn
          small
          color="primary mr-3"
          elevation="0"
          @click="fetchNotification">
          Search
        </v-btn>
			</v-col>
      <v-col lg="6" class="d-flex align-end justify-end">
        <v-btn
          class="white--text"
          color="primary"
          small
          :loading="submitting"          
          @click="create.show=true;fetchProgram();"
        >
          <v-icon class="mr-2">ri-add-line </v-icon> Notifikasi Baru
        </v-btn>
      </v-col>
    </v-row>
      <v-row>
        <v-col id="dt-program">          
         <!--  <program-all
            :loading="table_loading"
            @change="change"
            @detail="detail"
            @refresh-list="fetchAll"
            :data="{ perPage: limit, list, page, total }"
          ></program-all> -->
          <notif-all
              :loading="table_loading"
              :data="data"
              @remove="remove"              
              @refresh-list="fetchNotification"
            />            
        </v-col>
      </v-row>
      <v-dialog v-model="create.show" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span>  Buat Notifikasi </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-col cols="12">
                <div class="mb-2">Target</div>
                <v-select
                  :items="targets"
                  :item-text="'name'"
                  :item-value="'name'"
                  filled
                  v-model="target"
                ></v-select>
              </v-col>
              <v-col cols="12" v-show="target === 'Berdasarkan program/progress'">
                <div class="mb-2">Program</div>
                <!-- <v-select
                  :items="list_program"
                  :item-text="'name'"
                  :item-value="'name'"
                  filled
                  v-model="program"
                ></v-select> -->
                <v-autocomplete
                  v-model="program_"
                  :items="list_program"
                  :item-text="'title'"
                  :item-value="'id'"
                  dense
                  filled
                ></v-autocomplete>
              </v-col>
              <v-col id="progres-icon" cols="12" v-show="target === 'Berdasarkan program/progress'">
                <v-row>
                  <v-col cols="6">
                    <div class="mb-2">Progress (Batas Minimal)</div>
                    <v-text-field dense v-model.number="progress_start" type="number" append-icon="ri-percent-fill"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <div class="mb-2">Progress (Batas Maksimal)</div>
                    <v-text-field dense v-model.number="progress_end" type="number" append-icon="ri-percent-fill"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col v-if="this.target === 'Program Peserta'" cols="12">
                <div class="mb-2">Bidang Studi</div>
                <v-select
                  :items="list_studi"
                  :item-text="'name'"
                  :item-value="'name'"
                  filled
                  v-model="studi"
                ></v-select>
              </v-col> -->              
              <v-col cols="12">
                <div class="mb-2">Subjek</div>
                <v-text-field
                  dense
                  v-model="title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Pesan</div>
                <v-textarea filled hide-details="auto" class="border-0" v-model="desc"></v-textarea>
              </v-col>
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="create.show=false">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="save()"
            >
              Kirim
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="is_delete.show" max-width="600px">
        <v-card>
          <v-card-title>
            <span> <v-icon>$warning</v-icon> Hapus Notifikasi </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              Apakah anda ingin menghapus pesan dengan target <strong class="text-capitalize">{{ this.notif.target }} </strong>ini ?
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="is_delete.show=false">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="is_delete.show=false"
            >
              Ya, Hapus!
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import NotifAll from "./components/table";
import { mapGetters} from 'vuex'
// import moment from "moment";


export default {
  name: "user-list",
  components: { NotifAll},
  data: () => ({
    table_loading: false,    
    loading:false,
    submitting:false,
    notif:{},
    name:'',
    target:'Semua user',
    status:null,
    program_:'',
    progress_end:null,
    progress_start:null,
    title:'',
    desc:'',
    studi:'',
    list_program:[],
    targets:[
      {
        id:'1',
        name:'Semua user'
      },
      {
        id:'2',
        name:'Berdasarkan program/progress'
      },
      // {
      //   id:'3',
      //   name:'Program Peserta'
      // }
    ],
    list_status:[
      {
        id:'1',
        name:'0%-10%'
      },
      {
        id:'2',
        name:'11%-20%'
      },
      {
        id:'3',
        name:'21%-30%'
      }
    ],
    // list_program:[
    //   {
    //     id:'1',
    //     name:'Seniman Digital'
    //   },
    //   {
    //     id:'2',
    //     name:'Cara Jualan'
    //   },
    //   {
    //     id:'3',
    //     name:'Testing'
    //   }
    // ],
    list_studi:[
      {
        id:'1',
        name:'Teknologi informasi'
      },
      {
        id:'2',
        name:'Penjualan'
      },
      {
        id:'3',
        name:'Peternakan'
      }
    ],
    create: {
      show:false,
      id:''
    },
    is_delete: {
      show:false,
      id:''
    },
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,

  }),
  computed: {
    isPaketEmpty: () => false,
    // ...mapGetters("user", ["list", "page", "limit", "total"]),
    ...mapGetters({ data: "notif/data", params: "notif/params" }),
    // topics() {
    //   return this.topics || [];
    // },
  },
  mounted() {
    // this.$emit("page-changed", 0, { title: "Daftar Peserta", link: `/program/users/${this.$route.params.id}` });
    this.$emit("page-changed", 1, {
      title: "Daftar",
      link: this.$route.path,
    });
    this.fetchNotification();    
  },
  created(){
  },
  methods: {
    fetchAll(){
      this.fetchNotification();
    },
    fetchProgram() {
      this.axios
        .get("/program/v1/company/list?status=publish&limit=-1")
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data.list;
          this.list_program = res;
          // console.log('prog',this.list_program)
        });
    },
    async fetchNotification() {
      this.table_loading = true;
      // let user = {
      //   id:this.$route.params.id,
      //   q: this.name_program
      // }
      // let q= this.name_program      
      this.$store.dispatch("notif/list",{q: this.name}).finally(() => {
        this.table_loading = false;
      });
      
    },
    fetchSearch(value) {
      this.table_loading = true;
      this.$store.dispatch("user/list",this.$route.params.id,{
        q: value,page:1}).finally(() => {
        // console.log('q hasil',this.name)      
        this.table_loading = false;
      });          
    },
    save(){
      this.errors = null;
      setTimeout(() => {
        
          this.submitting=true;
          // if (this.progress_start === null && this.progress_end ===null && this.program_ === '') {
          //   const data = {
          //     program_id: this.program_,
          //     progress_start: -1,
          //     progress_end: -1,
          //     title: this.title,
          //     message: this.desc,
          //     link:'https'
          //   }
          //     this.axios
          //       .post(`notification/v1/create`, data)
          //       .then(() =>{
          //         this.submitting=false;
          //         this.snackbar.text = `Successfully send notification`;
          //         this.snackbar.state = true;   
          //         this.create.show=false;
          //         this.fetchNotification();    
          //       })
          //       .catch((res) => {
          //         this.submitting=false;          
          //         if (res.response) {
          //           this.snackbar.text = res.response.data.message;
          //           this.snackbar.state = true;
          //         }
          //       });
          // }else{            
            const data = {
              program_id: this.program_,
              progress_start: this.progress_start,
              progress_end: this.progress_end,
              title: this.title,
              message: this.desc,
              link:'https'
            }
            // console.log('data',data)
              this.axios
                .post(`notification/v1/create`, data)
                .then(() =>{
                  this.submitting=false;
                  this.snackbar.text = `Successfully send notification`;
                  this.snackbar.state = true;   
                  this.create.show=false;
                  this.fetchNotification();
                  this.program_='';
                  this.progress_start=null;
                  this.progress_end=null;
                  this.title='';
                  this.desc='' ;
                })
                .catch((res) => {
                  this.submitting=false;          
                  if (res.response) {
                    this.snackbar.text = res.response.data.message;
                    this.snackbar.state = true;
                  }
                });
          // }
        
      }, 50);
    },
    remove(notif) {
      this.is_delete.show=true;
      this.is_delete.id=notif.id;
      this.notif=notif;      
    },
    // hapus(id){
    //   this.is_change.id=id;
    //   console.log('id delete',this.is_change.id);
    //   this.submitting=true;
    //   this.axios
    //     .delete(`/users/v1/company/program/delete/${this.is_change.id}`)
    //     .then((res) =>{
    //       this.submitting=false;
    //       this.is_change.show=false;
    //       if (res.status == 200) {
    //         this.snackbar.text = `Program berhasil dihapus`;
    //         this.snackbar.state = true;
    //         this.fetchAll();

    //       }else{
    //         this.snackbar.text = res.message;
    //         this.snackbar.state = true;
    //       } 
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    forceFileDownload(url){
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.png') //or any other extension
      document.body.appendChild(link)
      link.click()
    },
          
  },
  watch: {
    progress_start(newVal){
      if(newVal === '') {
        this.progress_start = null;
      }
    },
    progress_end(newVal){
      if(newVal === '') {
        this.progress_end = null;
      }
    }
  },
};
</script>